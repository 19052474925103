<template>
  <div>
    <div class="air__utils__heading">
      <h5>Almacenes</h5>
    </div>
    <my-breadcrumbs :routes="routes" class="my-4"/>

    <div class="row pb-2">
      <div class="col">
        <a-alert message="Para comenzar elija una planta" type="warning" show-icon />
      </div>
    </div>

    <!-- Filtro de plantas para todos los tabs-->
    <div class="row justify-content-center mt-1 mb-4 py-3 bg-white">
      <div
        class="col-lg-8 col-sm-12"
      >
        <globalPlantFilter @filterValue="filterPlant = $event"/>
      </div>
      <div
        class="col-lg-4 col-sm-12"
      >
        <b-dropdown
          split block
          variant="primary"
          id="dropdownTrans"
          text="Nueva Transacción"
          split-variant="outline-primary"
          class="pt-4"
        >
          <b-dropdown-item :to="{name:'transactions', params: {type: 'Entrada'}}">
            Entrada
          </b-dropdown-item>
          <b-dropdown-item :to="{name:'transactions', params: {type: 'Salida'}}">
            Salida
          </b-dropdown-item>
          <b-dropdown-item :to="{name:'transactions', params: {type: 'Traspaso'}}">
            Traspaso
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <!-- Filtro de plantas para todos los tabs-->
    <div class="py-3 px-4 bg-white">
        <!-- Filtros-->
        <a-row class="mb-4 mt-4" align="middle" :gutter="[8,24]">
          <a-col :span="10">
            <!-- Select de Almacene-->
            <ResourceSelect
              @change="Search()"
              urlResource="/warehouses"
              v-model="WarehouseFilter"
              :filters="warehouseComboFilters"
              placeholder="Almacén (Seleccione una planta)"
            >
              <span slot-scope="{option}">{{option.name}}</span>
            </ResourceSelect>
          </a-col>
          <a-col :span="10">
            <!-- Select de Tipo de Transaccion-->
            <ResourceSelect
              @change="FetchTransactions()"
              placeholder="Tipo de Transacción"
              v-model="WarehouseTransactionFilter"
              urlResource="/warehouseTransactions"
              :filters="warehouseTransactionFilters"
            >
              <span slot-scope="{option}">{{option.name}}</span>
            </ResourceSelect>
          </a-col>
          <a-col :span="10">
            <!--DATEPICKER - Desde-->
            <a-date-picker
              showTime
              size="large"
              v-model="fromDate"
              placeholder="Desde"
              style="width: 100%"
              format="DD/MM/YYYY HH:mm"
              valueFormat="YYYY/MM/DD HH:mm"
            />
            <!--DATEPICKER - Desde -->
          </a-col>
          <a-col :span="10">
            <!--DATEPICKER - Hasta-->
            <a-date-picker
              showTime
              size="large"
              v-model="toDate"
              placeholder="Hasta"
              style="width: 100%"
              format="DD/MM/YYYY HH:mm"
              valueFormat="YYYY/MM/DD HH:mm"
            />
            <!--DATEPICKER - Hasta -->
          </a-col>
          <a-col :span="2">
            <b-button
              @click="Search"
              variant="primary"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </a-col>
        </a-row>

        <a-tabs v-model="tabKey" type="card" size="large">
          <a-tab-pane key="1">
            <span slot="tab">
              <a-icon type="table"/>
              Elementos de Almacén
            </span>
            <div class="card">
              <div class="card-body text-center">
                <div class="row mt-4 bg-white py-5" v-if="items.length > 0">
                  <div class="col-sm-12 col-md-6">
                    <titemsResume :items="items">
                      <a-button slot="btn-icon" slot-scope="{item}"  @click="Select(item)">
                        <b-icon icon="hand-index-thumb"></b-icon>
                      </a-button>
                    </titemsResume>
                  </div>
                  <div class="col-sm-12 col-md-6" v-if="Object.keys(selectedItem).length > 0">
                    <div class="container-fluid">
                      <div class="row justify-content-center" >
                        <a-descriptions bordered size="small" :title="'Elemento: ' + selectedItem.code"
                                        :column="{ md: 2, sm: 1 }">
                          <a-descriptions-item label="Material">
                            <strong>{{selectedItem.material_name}}</strong>
                          </a-descriptions-item>
                          <a-descriptions-item label="Antiguedad">
                            <span>{{moment(selectedItem.antiquity).format('DD/MM/YY')}} </span>
                            |
                            <span v-if="moment().diff(moment(selectedItem.antiquity), 'days') > 1">
              hace {{ moment().diff(moment(selectedItem.antiquity), 'days') }} días
            </span>
                            <span v-else>{{moment(selectedItem.antiquity).fromNow()}}</span>
                            &nbsp;|
                            <a-icon v-if="moment().diff(moment(selectedItem.antiquity), 'days') > 150"
                                    type="warning" theme="twoTone" two-tone-color="#f2a654"/>
                            <a-icon type="like" theme="twoTone" two-tone-color="#00a654" v-else/>
                          </a-descriptions-item>
                          <a-descriptions-item label="Estatus">
                            <titemStatus :status="selectedItem.status"/>
                          </a-descriptions-item>
                          <a-descriptions-item label="Contenedor">
                            <strong >{{ selectedItem.last_tdetail.containerTypes.name}}</strong>
                          </a-descriptions-item>
                          <a-descriptions-item label="Posición">
                            <strong v-if="selectedItem.wpositions">{{ selectedItem.wpositions.name }}</strong>
                            <span v-else> NA</span>
                          </a-descriptions-item>
                        </a-descriptions>
                      </div>
                      <div class="row justify-content-center mt-5">
                        <a-timeline mode="alternate">
                          <a-timeline-item v-for="tdetail in selectedItem.tdetails" :key="tdetail.id" :color="color(tdetail.transactions.warehouseTransactions.name)">
                            <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
                            <b-container fluid>
                              <div class="row">
                                <div class="col-12">
                                  <strong class="font-size-14 text-real-blue"> {{tdetail.transactions.code}}</strong>
                                </div>
                                <div class="col-6">
                                  <strong class="font-size-14">Tipo:</strong>
                                </div>
                                <div class="col-6">
                                  <span class="font-size-12"> {{tdetail.transactions.warehouseTransactions.name}}</span>
                                </div>
                                <div class="col-6">
                                  <strong class="font-size-14">Almacén:</strong>
                                </div>
                                <div class="col-6">
                                  <span class="font-size-12"> {{tdetail.transactions.warehouses.name}}</span>
                                </div>
                                <div class="col-6">
                                  <strong class="font-size-14">Fecha:</strong>
                                </div>
                                <div class="col-6">
                                  <span class="font-size-12"> {{moment(tdetail.transactions.dated_at).format('DD/MM/YY HH:mm')}}</span>
                                </div>
                                <div class="col-6">
                                  <strong class="font-size-14">Contenedores:</strong>
                                </div>
                                <div class="col-6">
                                  <span class="font-size-12"> {{tdetail.container_quantity}}</span> <strong>{{tdetail.containerTypes.name}}</strong>
                                </div>
                                <div class="col-6">
                                  <strong class="font-size-14">Cantidad Real:</strong>
                                </div>
                                <div class="col-6">
                                  <span class="font-size-12"> {{tdetail.real_quantity}}</span> <strong>{{tdetail.ums.name}}</strong>
                                </div>
                              </div>
                            </b-container>
                          </a-timeline-item>
                        </a-timeline>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <span slot="tab">
              <a-icon type="retweet"/>
              Transacciones
            </span>
            <div class="card">
              <div class="card-body text-center">
                <!-- TABLA PRINCIPAL -->
                  <a-table v-show="filterPlant"
                           class="mt-5"
                           :columns="transactionsTable.columns"
                           :data-source="transactionsTable.data"
                           :pagination="transactionsTable.pagination"
                           :loading="transactionsTable.loading"
                           @change="FetchTransactions"
                           :row-key="record => record.id" bordered>
                    <!-- TABLA DETALLE -->
                    <a-table slot="expandedRowRender" class="bg-gray-3"
                             slot-scope="row"
                             :columns="tdetailColumns"
                             :data-source="row.tdetails"
                             :pagination="false"
                             :row-key="record => record.id">
                      <template slot="info" slot-scope="row">
                        <a-descriptions bordered size="small"
                                        :column="{ sm: 1, md: 3 }">
                          <a-descriptions-item label="Residuo">
                            <strong>{{row.sapMaterialContracts.materials.name}}</strong>
                          </a-descriptions-item>
                          <a-descriptions-item label="Contenedores">
                            <strong>{{row.container_quantity}} </strong> {{row.containerTypes.name}}
                          </a-descriptions-item>
                          <a-descriptions-item label="Real">
                            <strong>{{row.real_quantity}}  </strong> {{row.ums.name}}
                          </a-descriptions-item>
                        </a-descriptions>
                      </template>
                    </a-table>
                    <!-- TABLA DETALLE -->
                    <template slot="general" slot-scope="data">
                      <a-tabs type="card">
                        <a-tab-pane key="1" tab="Información">
                          <a-descriptions bordered size="small" :column="{ sm: 1, md: 2 }">
                            <a-descriptions-item label="Folio">
                              <strong>{{data.code}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Tipo de Transacción">
                              <strong class="text-white py-1 px-2 rounded"
                                      :class="{ 'bg-primary': data.warehouseTransactions.name == 'Entrada a Almacén',
                                   'bg-danger': data.warehouseTransactions.name == 'Salida de Almacén',
                                   'bg-warning': data.warehouseTransactions.name == 'Entrada por Traspaso' || data.warehouseTransactions.name == 'Salida por Traspaso'}"
                              >
                                {{data.warehouseTransactions.name}}
                              </strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Fuente de Transacción">
                              <strong v-if="data.transactionable_type == 'App\\Models\\User' ">
                                Interna
                              </strong>
                              <strong v-else-if="data.transactionable_type == 'App\\Models\\WorkCenter' ">
                                Externa
                              </strong>
                              <strong v-else>Servicio</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Detalle de la Fuente">
                              <strong v-if="data.transactionable_type == 'App\\Models\\User' || data.transactionable_type == 'App\\Models\\WorkCenter'">
                                {{data.transactionable.name}}
                              </strong>
                              <!--  ServicePlannings  -->
                              <strong v-else>{{ data.transactionable.code }}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Almacén">
                              <strong>{{data.warehouses.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Fecha y Hora de entrada">
                              <strong>{{moment(data.dated_at).format('DD/MM/YY HH:mm')}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Responsable">
                              <strong>{{data.users.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Turno">
                              <strong>{{data.operationShifts.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Área" v-if="data.operationAreas">
                              <strong>{{data.operationAreas.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Ir a Historial">
                              <b-button
                                pill block
                                class="mr-1 sm"
                                variant="success"
                                @click="$router.push({name: 'item_history', params: { transactionCode: data.code } })"
                              >
                                <b-icon icon="clock-history"/> Historial
                              </b-button>
                            </a-descriptions-item>
                          </a-descriptions>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="Notas">
                          <div class="row justify-content-center">
                            <strong>Referencia:</strong>
                          </div>
                          <div class="row justify-content-center">
                            <p>{{data.reference}}</p>
                          </div>
                          <div class="row justify-content-center">
                            <strong>Notas Especiales:</strong>
                          </div>
                          <div class="row justify-content-center">
                            <p>{{data.comments}}</p>
                          </div>
                        </a-tab-pane>
                        <a-tab-pane key="3" tab="Manifiesto" v-if="data.exitManifests">
                          <a-descriptions bordered size="small" :column="{ sm: 1, md: 2, xl: 4 }">
                            <a-descriptions-item label="Folio">
                              <strong>{{data.exitManifests.code}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Tipo de Manejo">
                              <strong>{{data.exitManifests.managementTypes.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Tipo de Formulación">
                              <strong>{{data.exitManifests.formulationTypes.name}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Fecha de reciclaje">
                              <strong>{{moment(data.exitManifests.dated_at).format('DD/MM/YY HH:mm')}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Transporte de Salida">
                              <strong>{{data.exitManifests.trucks.tag}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="No. Autorización SEMARNAT">
                              <strong>{{data.exitManifests.semarnat}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="No. Autorización de destino final">
                              <strong>{{data.exitManifests.final_destiny}}</strong>
                            </a-descriptions-item>
                            <a-descriptions-item label="Razón social de destino final">
                              <strong>{{data.exitManifests.social_reason}}</strong>
                            </a-descriptions-item>
                          </a-descriptions>
                        </a-tab-pane>
                      </a-tabs>
                    </template>
                  </a-table>
                <!-- TABLA PRINCIPAL -->
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
<!--    <wpositionDrawer :with-controls="false"/>-->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
import ResourceSelect from '@/components/ResourceSelect'
import titemsResume from './components/titemsResume'
import titemStatus from './components/titemStatus'
// import wpositionDrawer from './components/wpositionDrawer'
export default {
  name: 'warehouseMain',
  mixins: [fractalMixin],
  components: {
    ResourceSelect,
    // tabs
    titemsResume,
    titemStatus,
    // wpositionDrawer,
  },
  data() {
    return {
      // Tabs
      tabKey: '1',
      // Contants
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Operaciones',
          aIcon: '',
          name: 'operations',
        },
        {
          breadcrumbName: 'Menú de Almacén',
          aIcon: '',
          name: 'warehouse_menu',
        },
        {
          breadcrumbName: 'Gestión de Almacenes',
          aIcon: '',
        },
      ],
      // Slider
      filterPlant: '',
      // shared filters
      WarehouseFilter: undefined,
      // filters tab de transaccciones
      warehouseTransactionFilters: { sortBy: 'name', 'fields[processType]': 'Operaciones', 'fields[warehouseTransactions]': 'name' },
      WarehouseTransactionFilter: undefined,
      fromDate: '',
      toDate: '',
      // transaction table
      transactionsTable: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Transacciones',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'general' },
          },
        ],
      },
      tdetailColumns: [
        {
          title: 'Detalle de Transacción',
          dataIndex: '',
          class: 'text-center',
          scopedSlots: { customRender: 'info' },
          // width: '20%',
        },
      ],
      // Tab de elementos
      items: [],
      selectedItem: {},
    }
  },
  computed: {
    warehouseComboFilters() {
      return {
        'filter[type]': 'Operaciones',
        'filter[workCenters]': this.filterPlant,
        'fields[warehouses]': 'name',
      }
    },
    ...mapState('warehouse', ['visibleDrawer']),
  },
  methods: {
    ...mapActions('warehouse', ['TOOGLE_DRAWER', 'STORE_DETAILWPOSITIONDRAWER']),
    // Search
    async Search() {
      if (this.tabKey === '1') {
        await this.FilterData()
      } else {
        await this.FetchTransactions()
      }
    },
    async FetchTransactions(pagEvent) {
      const params = {
        sort: '-code',
        include: 'tdetails.containerTypes,' +
          'tdetails.ums,' +
          'tdetails.sapMaterialContracts.materials,' +
          'tdetails.sapMaterialContracts.clientContracts.clients,' +
          'exitManifests.managementTypes,' +
          'exitManifests.formulationTypes,' +
          'exitManifests.trucks,' +
          'transactionable,' +
          'warehouseTransactions,warehouses,users,operationShifts,operationAreas',
        'fields[warehouseTransactions]': 'name',
        'fields[users]': 'name',
        'fields[operationShifts]': 'name',
        'fields[operationAreas]': 'name',
        'fields[trucks]': 'tag',
        'fields[managementTypes]': 'name',
        'fields[formulationTypes]': 'name',
        'filter[global]': this.filterValue,
      }
      if (this.WarehouseFilter) params['filter[warehouses]'] = this.WarehouseFilter
      if (this.WarehouseTransactionFilter) params['filter[warehouseTransactions]'] = this.WarehouseTransactionFilter
      if (this.fromDate !== '' && this.toDate !== '' && this.fromDate !== null && this.toDate !== null) {
        params['filter[period]'] = `${this.fromDate},${this.toDate}`
      }
      await this.GetTableResource(pagEvent, 'transactions', this.transactionsTable, params)
    },
    // Tab de elementos
    async FilterData() {
      const params = {
        include: 'tdetails.transactions.warehouses,tdetails.transactions.warehouseTransactions,tdetails.transactions,tdetails.containerTypes,tdetails.ums,wpositions',
        // 'fields[tdetails]': 'code,status,material_name,antiquity,transactions,containerTypes,ums',
        'fields[transactions]': 'code,dated_at,warehouseTransactions,warehouses',
        'fields[containerTypes]': 'name',
        'fields[ums]': 'name',
        'fields[wpositions]': 'name',
        'fields[warehouseTransactions]': 'name',
        'fields[warehouses]': 'name',
      }

      if (this.WarehouseFilter) {
        params['filter[warehouses]'] = this.WarehouseFilter
      }
      this.items = await this.GetResource('/titems', params)
      this.selectedItem = {}
    },
    Select(item) {
      this.selectedItem = item
    },
    color(warehouseTransactionsName) {
      if (warehouseTransactionsName === 'Entrada a Almacén' || warehouseTransactionsName === 'Entrada por Traspaso') {
        return 'green'
      } else if (warehouseTransactionsName === 'Salida de Almacén' || warehouseTransactionsName === 'Salida por Traspaso') {
        return 'red'
      } else return 'blue'
    },
    showPositionDrawer(e, position) {
      e.preventDefault()
      // console.log(position)
      // this.TOOGLE_DRAWER(true)
      // this.STORE_DETAILWPOSITIONDRAWER(position)
    },
  },
}
</script>

<style scoped>
</style>
